<!--我的碗卡-->
<template>
    <div class="body">
        <div class="content">
            <van-list
                    v-model="listLoading"
                    :finished="listLoadingFinished"
                    finished-text="没有更多了"
                    @load="onLoad"
            >
                <div class="card" v-for="(item, index) in listLoadingList" :key="index">
                    <div class="card_content1">
                        <div class="p2">{{item.gooods_name}}</div>
                        <p class="available">{{orderStatus(item.reg,item.payType,item.type)}}</p>
                    </div>
                    <div class="card_content2">
                        <img src="https://seetop-1257860468.file.myqcloud.com/html/img/yiwan20201201.png" class="img1">
                        <div>
                            <p class="p3">账号：{{item.cardNumber}}</p>
                            <p class="p3">密码：{{item.password}}</p>
                        </div>
                    </div>
                    <div class="card_content3">
                        <div class="p4">{{item.create_time}}</div>
                        <div class="use" v-if="item.reg==0 && item.payType=='buy'" @click="toUse(item.cardNumber,item.password)">去使用</div>
                    </div>
                </div>
            </van-list>
        </div>
        <float-nav></float-nav>
    </div>
</template>

<script>
  import { globalConfig, listLoading } from '../../utils/mixin'
  import FloatNav from '../../components/common/FloatNav'

  export default {
    name: 'CardList',
    components: { FloatNav },
    mixins: [globalConfig, listLoading],
    methods: {
      orderStatus: function (reg, type, sign) {
        if (type === 'buy') {
          if (sign === '1') {
            switch (reg) {
              case '0':
                return '碗卡未使用'
              case '1':
                return '碗卡已使用'
              case '-1':
                return '碗卡已注销'
              default:
                break
            }
          } else if (sign === '2') {
            switch (reg) {
              case '0':
                return '月卡未使用'
              case '1':
                return '月卡已使用'
              case '-1':
                return '月卡已注销'
              default:
                break
            }
          }
        } else {
          return '续卡'
        }
      },
      toUse: function (card, password) {
        this.$router.push('/activation/' + card + '/' + password)
      }
    },
    mounted () {
      this.listLoadingInit(this.apiUrl + 'mall/order/getOrderList', 'list', 1, {
        status: 0
      })
    }
  }
</script>

<style scoped>
    .body{
        position: absolute;
        min-height: 100%;
        min-width: 100%;
        background-color:#efefef;
    }
    .card{
        width: 94vw;
        margin: 3vw 3vw;
        background-color: #ffffff;
        border-radius: 2vw;
        padding: 2vw 0;
    }
    .img1{
        width: 16vw;
        height: 16vw;
    }
    .card_content1{
        width: 86vw;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        border-bottom: 0.01rem solid #eeeeee;
        margin: 0 4vw;
        padding: 2vw 1vw;
    }
    .card_content2{
        width: 86vw;
        display: flex;
        align-items: center;
        flex-flow: row;
        margin: 0 4vw;
        padding: 2vw 1vw;
    }
    .card_content3{
        width: 86vw;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: flex-end;
        margin: 0 4vw;
        padding: 2vw 1vw;
    }
    .p2{
        font-size: 3.2vw;
        color: #666;
        letter-spacing: 0.2vw;
    }
    .p3{
        font-size: 3vw;
        color: #666;
        font-weight: 500;
        letter-spacing: 0.2vw;
        margin: 1vw 3vw;
    }
    .p4{
        font-size: 2.8vw;
        color: #666;
        letter-spacing: 0.2vw;
    }
    .available{
        color: #FF795A;
        font-size: 0.9rem;
    }
    .use{
        border-radius: 4vw;
        border: 1px solid #999;
        font-size: 3.2vw;
        color: #999;
        padding: 0.5vw 4vw;
    }
    .loading{
        font-size: 3.8vw;
        color: #999;
    }
</style>
